
.section .content h1, h2, h3{
    margin: 0 0 12px 0;
}

@media print {
    .section .content .edu h1, h2, h3 {
        margin-bottom: 6px;
    }
}

.section .content h4 {
    margin: 0 0 7px 0;
}

.section .content ul, ol {
    margin: 0;
    padding-inline-start: 15px;
}

.section .content .edu ul {
    padding: 0;
}

.section .content ul>li>h3 {
    text-decoration: none;
    text-transform: uppercase;
}

.section .content ul>li>h3>span {
    text-decoration: underline;
}

.section .content ul.ui--diamond>li>h3>span {
    text-decoration: none;
}

.section .content>div {
    margin: 12px 0;
}

.section .content div>ul>li>ul {
    margin-left: 40px;
}

@media print {
    .section .content ul.ui--diamond {
        margin-top: 20px;
    }

    .section .content .edu ul.ui--diamond {
        margin-top: 0;
        margin-left: 30px;
    }

    .section .content .edu ul>li>ul {
        margin-top: 5px;
        margin-left: 30px;
    }

    .section .content .cultPrefs ul {
        margin-top: 0;
    }

    .section .content .cultPrefs ul>li>ol {
        padding-top: 5px;
    }

    .section .content div>ul>li>ul {
        margin-left: 0;
        padding-left: 0;
    }

    .section .content div>ul>li>ul >li>ul {
        margin-left: 0;
        padding-left: 5px;
    }
}

.section .content li>span {
    font-weight: bold;
}

.section .content div>ul>li>ul>li>ul {
    margin-top:5px;
}

.section .content li {
    margin-bottom: 10px;
}

.section .content li:last-child {
    margin-bottom: 0;
}

.section .content .job_title_list > li {
    margin: 6px 25px;
    font-size: 10.5pt;
    letter-spacing: 0.01rem !important;
}

.section .content div>ul>li>ul>li>ul {
    margin-left: 30px;
}

.section .content div>ul>li>ul>li>ul>li {
    margin-bottom: 3px;
}

@media print {

    .section .content .job_title_list > li {
        margin: 3px 25px;
        font-size: 9pt;
        letter-spacing: 0.01rem !important;
    }

    .section .content div>ul>li>ul>li>ul>li {
        margin-bottom: 1px;
    }

}



.section .content .sec_spacer {
    height: 16px;
}

.section .content .ui--diamond>li::marker {
    content: "⟡   ";
}

.section .content .ui--diamond>li>h3>span {
    font-size: 9.5pt;
    text-transform: lowercase;
}

.section .content .ui--diamond>li>h3 {
    padding-top: 25px;
}

@media print {
    .section .content .ui--diamond>li>h3 {
        padding-top: 15px;
    }

    .section .content>.edu>.ui--diamond>li>h3 {
        padding-top: 0;
    }
}

.section .content .ui--no_bullet {
    list-style-type: none;
}

.section .content .ui--diamond>li>.ui--no_bullet>li>span {
    text-decoration: underline;
}

.section .content .ui--no_bullet>li>.ui--no_bullet>li>span {
    text-decoration: underline;
}

.section .content .toc {
    margin-top: 0;
    margin-left: 35px;
    margin-bottom: 0;
}

.section .content .toc > li {
    margin-bottom: -1px;
    vertical-align: middle;
}

.section .content .toc > li::marker {
    content: "\2610   ";
    font-size: 15pt;
}

.section .content .toc > li > a > div > span {
    font-size: 10pt;
}

.section .content .toc > li > a {
    font-size: 11pt;
}

.section .content .toc > li > a > span {
    font-size: 9.5pt;
}

.section .content .toc > li > a {
    color: #8427a9;
    text-decoration: none;
}

.section .content a {
    color: #8427a9;
    text-decoration: none;
}

.section .content > div > p {
    margin: 10px 0;
}

.section .content > div > p:first-of-type {
    /*margin-top: 5px;*/
    margin-top: 0;
}

.section .content > div > p:last-of-type {
    /*margin-bottom: 5px;*/
    margin-bottom: 0;
}

@media print {

    /*.section .content > div > p {*/
    /*    margin: 8px 0;*/
    /*}*/

    .section .content > div > p:first-of-type {
        margin-top: 0;
    }

    .section .content > div > p:last-of-type {
        margin-bottom: 0;
    }
}