
:root {
    --bgcol: #6e6d8a;
    --bgcol2: #68777c;
    --bgcol3: #779eab;
    --font1: Lato;
    --letspace: .1rem;
    --topmarg: 55px;
    --max-content-width: 1100px;
}

@page  {
    margin: 0;
    size: letter;
}

.setDarkBgColor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #363636;
}

.intro_flex_container {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    padding-left: calc((100vw - var(--max-content-width) - 65px)/2) !important;
    padding-top: 1px;
    padding-bottom: 30px;
    color: #444;
    font-family: var(--font1);
    font-size: 10pt;
    letter-spacing: 0.03rem;
    line-height: 1.2rem;
}

@media print {
    .intro_flex_container {
        line-height: 1.05rem;
        padding-bottom: 35px;
    }
}

.intro_flex_container .heading {
    /*letter-spacing: 0.02em !important;*/
    text-transform: unset !important;
}

.intro_flex_container .heading > span {
    letter-spacing: 0.01em !important;
    text-transform: unset !important;
}

.intro_title {
    font-size: 11pt !important;
    letter-spacing: 0.05em;
}

@media print {

    .intro_title {
        font-size: 10pt !important;
    }

}

.intro_header_loc {
    padding-top: 0 !important;
    width: 100%;
}

@media print {
    .intro_flex_container {
        font-size: 8.5pt;
    }

    .intro_title {
        font-size: 10pt;
    }

}

.header_flex_container {
    padding: 75px 55px 0 55px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;

    background-color: #363636;
    color: #444;
    font-family: var(--font1);
    font-size: 10pt;
    letter-spacing: 0.02rem;
    line-height: 1.2rem;
}

.footer_flex_container {
    padding: 15px calc((100vw - var(--max-content-width) - 50px)/2) 15px !important;
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    background-color: #363636;
    color: #444;
    font-family: var(--font1);
    font-size: 10pt;
    letter-spacing: 0.02rem;
    line-height: 1.2rem;
}

.header_text {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 95%;
    justify-content: center;
    color: white;
    font-size: 13pt;
}

.header_text div {
    display: flex;
    flex-direction: column;
    flex-grow: 0;

    margin-right: 20px !important;
}

.header_text p {
    margin: -17px 0 18px 68px;
}

.header_text a {
    color: white;
    text-decoration: none;
}

.bagImg {
    max-width: 200px;
    padding-right: 50px;
    margin-bottom: -3px;
}

.titleImg {
    /*max-width: 939px;*/
    max-width: 711px;
    margin-top: -10px;
}

.pdfDownloadPadding {
    padding-left: 25px;
    padding-top: 20px;
}

.mobile_pdfDownloadAnimate {
    animation-name: mobile_bounce;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    animation-duration: 2s;
    animation-iteration-count: 1;
}

@keyframes mobile_bounce {
    0%   { transform: scale(1,1)      translateX(0); }
    10%  { transform: scale(1.1,.9)   translateX(-20px); }
    30%  { transform: scale(.9,1.1)   translateX(17px); }
    45%  { transform: scale(1.05,.95) translateX(-10px); }
    55%  { transform: scale(1,1)      translateX(10px); }
    60%  { transform: scale(1,1)      translateX(0); }
    100% { transform: scale(1,1)      translateX(0); }
}

.pdfDownloadAnimate {
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    animation-duration: 2s;
    animation-iteration-count: 1;
}

.pdfDownload
{
}

@keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-50px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

@media print {

    .intro_flex_container {
        padding-left: unset !important;
    }

    .pdfDownloadHideOnPrint {
        display: none;
    }

    .header_flex_container {
        max-width: unset !important;
        padding: 40px 20px 0 45px !important;
    }

    .header_flex_container .intro_flex-container {
        max-width: unset !important;
        padding: 55px 20px 0 55px !important;
    }

    .header_text {
        font-size: 9pt;
        padding-right: 10px;
    }

    .header_text div {
        justify-content: center;
    }

    .header_text p {
        display: flex;
        margin: -7px 0 18px 25px;
    }

    .bagImg {
        max-width: 190px;
        padding-right: 35px;
        margin-left: -8px;
    }

    .titleImg {
        width: 100% !important;
        margin-right: 90px;
        /*margin-top: -5px;*/
    }

    .footer_flex_container {
        padding: 15px 35px 15px 35px !important;
    }
}

.footer_text {
    display: flex;
    flex-direction: row;
    width: 100vw;
    color: white;
}

.footer_text img {
    padding-left: 70px;
    width: 70px !important;
}

@media print {
    .footer_text img {
        padding-left: 72px;
        padding-top: 10px;
        width: 60px !important;
    }
}

.footer_text a {
    color: white;
    text-decoration: none;
    align-content: center;
}

.footer_text .footer_copyright {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;

    text-align: right;
    text-decoration: none;
}