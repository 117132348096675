
/* HEADER */

.mobile_sections {
    background-color: #6e6d8a44;
    padding: 11px 0;
}

.mobile_header_flex_container {
    padding: 15px 15px 0 15px;
    margin-bottom: -8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    background-color: #363636;
    color: #444;
    font-family: var(--font1);
    letter-spacing: 0.02rem;
    line-height: 1.2rem;
}

.mobile_header_text {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-self: end;

    align-items: end;
    color: white;
    font-size: 10.5pt;
}

.mobile_header_text div {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
}

.mobile_header_textdiv {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mobile_header_text p {
    /*margin: -17px 0 18px 68px;*/
    margin: 0 0 0 0;
}

.mobile_header_text a {
    color: white;
    text-decoration: none;
}

.mobile_bagImg {
    display: flex;
    max-width: 27vw;
    padding-right: 0;
}

.mobile_titleImg {
    max-width: 65vw;
    margin-top: 0;
}

.mobile_pdfDownloadPadding {
    padding-right: 20px;
}

@media print {

    .mobile_pdfDownloadHideOnPrint {
        display: none;
    }
}

/* MAIN CONTENT BOX - Acknowledgements */

.mobile_content_box {
    margin: 30px 20px;
    line-height: 1.3em;
    font-size: 10.5pt;
}

.mobile_content_box > .descPara {
    margin: unset !important;
}

.mobile_content_box > div > p.listPara {
    margin: 20px 30px 0 30px !important;
}

.mobile_content_box > div > p > ul {
    margin: 10px 0;
    padding: 0;
}

.mobile_content_box > div > p > ul > li {
    margin: 10px 0;
    padding: 0;
}

.mobile_content_box > div > p > ul > li > ul > li {
    margin: 3px 0;
    padding: 0;
}

.mobile_header_content_text > .acknowledge_text > p > ul > li > ul {
    margin-top: 4px;
}

.mobile_header_content_text > .acknowledge_text > p.listPara > ul > li {
    margin-bottom: 12px;
}


/* INTRO */

.mobile_section {
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}

.mobile_section a {
    text-decoration: none;
}

.mobile_section .job_title_list > li {
    margin: 8px 0;
}

.mobile_intro_box {
    display: flex;
    flex-direction: column;

    margin: 2px 0;
    padding: 5px 15px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid white;

    /*border: 6px dashed #9bd7ea;*/
    /*box-shadow: 0 0 8px 0 lightgrey, 0 0 15px 0 black;    */

    /*border-bottom: 1px solid lightgrey;*/
    /*border-radius: 2px 0 0 2px;    */

    /*border-radius: 3px;*/
    /*border: 1px solid #a9a9a9;    */
}

.mobile_header_title_text > div > span {
    font-size: smaller;
}

.mobile_header_title_text  a {
    color: #ecb56d;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
}

.mobile_header_subtitle_text  a {
    color: #022859;
    text-decoration: none;
    text-transform: uppercase;
}

.mobile_header_subtitle_text > div > ul > li > a > span {
    font-size: x-small;
}

.mobile_header_subtitle_text > div > ul.toc > li {
    margin: 5px 0;
}

.mobile_header_subtitle_text > div > ul.toc {
    margin-bottom: 0;
}

.mobile_header_title_text {
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: 11pt;
    line-height: 20px;

    color: white;
    background-color: #1284d0;
}

.mobile_header_title_text p {
    margin: 5px auto;
}

.mobile_header_subtitle_text {
    letter-spacing: 0.02em;
    font-size: 10pt;
    line-height: 13pt;
    color: #131313;
    background-color: #98d0f3;
}

.mobile_header_content_text {
    letter-spacing: 0.02em;
    font-size: 9.5pt;
    line-height: 12.5pt;
    color: #131313;
    background-color: #ecebf1;
    display: flex;
    flex-direction: column;
}

.mobile_header_content_text > div > ul {
    margin: 0 0 40px 0;
}

.mobile_header_content_text > div > ul > li {
    margin: 2px 0;
}

.mobile_header_content_text > div > ul > li > a {
    font-size: 10.5pt;
    text-decoration: none !important;
}

.mobile_header_content_text > div > ul {
    margin-left: -5px;
}

.mobile_header_content_text > div > ul.ui--diamond {
    margin-left: -10px !important;
}

.mobile_header_content_text .ui--diamond>li::marker {
    content: "⟡   ";
}

.mobile_header_content_text h4 {
    margin-left: 10px;
    font-size: 10pt;
}

.mobile_intro_box .toc {
    margin-left: 15px;
    margin-bottom: 25px;
}

.mobile_header_content_text .toc {
    margin-left: 40px;
}

.mobile_intro_box .toc > li::marker {
    content: "\2610   ";
    font-size: 15pt;
}

.mobile_intro_box ul.ui--diamond > li > h3 {
    padding-top: 15px;
}

.mobile_intro_box ul.ui--diamond > li > ul.ui--no_bullet {
    list-style-type: none;
    margin-bottom: 28px;
}

.mobile_intro_box ul.ui--diamond > li > ul.ui--no_bullet > li >   {
    text-decoration: underline;
}

.mobile_intro_box ul.ui--diamond > li > ul.ui--no_bullet > li > ul {
    margin-bottom: 8px;
}

.mobile_intro_box ul.ui--diamond > li > ul.ui--no_bullet > li > ul > li  {
    text-decoration: none;
    margin: 4px 0;
}

.mobile_intro_box ul.ui--diamond > li > ul.ui--no_bullet > li > ul > li > a  {
    text-decoration: none;
}

.mobile_intro_box ul.ui--diamond > li > ul.ui--no_bullet > li > ul.ui--no_bullet {
    list-style-type: none;
}


.mobile_intro_box ul.ui--diamond>li>ul>li>span {
    text-decoration: underline;
    font-weight: bold;
}

.mobile_intro_box ul.ui--no_bullet>li>ul.ui--no_bullet>li>span {
    text-decoration: underline;
    font-weight: bolder;
}

.mobile_intro_box ul.ui--no_bullet>li>ul>li>span {
    text-decoration: none;
    font-weight: bolder;
}

/* Resume - Education */
.mobile_header_content_text .edu {
    padding: 10px 12px;
}


.mobile_header_content_text .edu > ul {
    margin: 5px 0 -10px 0;
    padding-left: 0;
}

.mobile_header_content_text .edu > ul > li > ul > li {
    margin: 18px 3px;
}

.mobile_header_content_text .edu > ul > li > ul > li > span {
    font-weight: bold;
}

.mobile_header_content_text .edu > ul > li > ul > li > ul {
    margin-top: 6px;
}

/* Resume - Culture Prefs */

.mobile_header_content_text .cultPrefs {
    padding: 10px 12px 10px 5px;
}


.mobile_header_content_text .cultPrefs > ul {
    margin: 5px 0 0 0;
}

.mobile_header_content_text .cultPrefs > ul > li > ol {
    list-style-type: none;
}

.mobile_header_content_text .cultPrefs > ul > li > ol > li {
    margin: 18px 3px;
}

.mobile_header_content_text .cultPrefs > ul > li > ol > li > span {
    font-weight: bold;
}

.mobile_header_content_text .cultPrefs > ul > li > ol > li > ul {
    margin-top: 6px;
}

/* FOOTER */

.mobile_footer_box {
    padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    background-color: #363636;
    color: #444;
    font-family: var(--font1);
    font-size: 10pt;
    letter-spacing: 0.02rem;
    line-height: 1.2rem;
}

.mobile_footer_text {
    display: flex;
    flex-direction: row;
    width: 100vw;
    color: white;

    align-items: center;
}

.mobile_footer_text img {
    max-width: 15vw;
    max-height: 90%;
}

.mobile_footer_text a {
    color: white;
    text-decoration: none;
}

.mobile_footer_text .footer_copyright {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;

    text-align: right;
    text-decoration: none;
}