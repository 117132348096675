:root {
    --max-mobile-width: 1110px;
}

@media screen and (max-width: 1110px) {

    div.showOnMobileOnly {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    div.showFullLayout {
        display: none !important;
    }
}

@media screen and (min-width: 1111px) {

    div.showOnMobileOnly {
        display: none !important;
    }

    div.showFullLayout {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}


@media print {

    div.showOnMobileOnly {
        display: none !important;
    }
    div.showFullLayout {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}


@media screen {

    .page-enter {
        opacity: 0;
        /*transform: scale(1.1);*/
    }

    .page-enter-active {
        opacity: 1;
        /*transform: scale(1);*/
        /*transition: opacity 250ms, transform 250ms;*/
        transition: opacity 250ms;
    }

    .page-exit {
        opacity: 1;
        /*transform: scale(1);*/
    }

    .page-exit-active {
        opacity: 0;
        /*transform: scale(0.9);*/
        /*transition: opacity 250ms, transform 250ms;*/
        transition: opacity 250ms;
    }

}