
:root {
    --bgcol: #6e6d8aaa;
    --bgcol2: #68777c;
    --bgcol3: #779eab;
    --font1: Lato;
    --letspace: .1rem;
    --topmarg: 55px;
    --max-content-width: 1100px;
}

@page  {
    margin: 0;
    size: letter;
}

.flex-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-left: calc((100vw - var(--max-content-width) - 65px)/2) !important;

    background-color: var(--bgcol);
    color: #444;
    font-family: var(--font1);
    font-size: 10pt;
    letter-spacing: 0.025rem;
    line-height: 1.2rem;
}

@media print {

    .flex-container {
        padding-left: unset !important;
        font-size: 9pt;
        line-height: 1.05rem;
    }
}

.resume_headers {
    padding: 1px 40px 1px 25px;

    display: flex;
    flex-direction: column;
}

.resume_header {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: var(--font1);
    letter-spacing: var(--letspace);
    text-align: center;
    width: 2.35in;
}



.header_loc {
    display: flex;
    flex-direction: column;
    min-width: 2.35in;
    padding: var(--topmarg) 0;

    text-align: center;
}

@media print {

    .resume_headers {
        padding: 1px 25px 1px 32px !important;
    }

    .resume_header {
        width: 2.08in;
    }

    .header_loc {
        padding: 45px 0;
        min-width: unset !important;
        max-width: 200px !important;
    }

    .header_loc .location {
        margin-bottom: 3px;
    }

    .fix_on_print{
        position: fixed;
    }
    /*@page :first {*/
    /*    .fix_on_print {*/
    /*        position: fixed;*/
    /*        !*display: none;*!*/
    /*    }*/
    /*}*/

}

.pdfDownloadResumeSec
{
    padding-left: 12px;
    margin-bottom: -7px;
    margin-right: -5px;

    animation-name: pulse;
    animation-timing-function: ease-in;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scaleX(1)
    }
    3% {
        transform: scale3d(1.25, 1.25, 1.25)
    }
    6% {
        transform: scaleX(1)
    }
}

.resume_header .title {
    padding: 8px 10px;
    margin-bottom: 22px;
    background-color: #eee;
    border-radius: 2px;
    border: 2px solid white;
    /*box-shadow: 1px 1px 7px 0px #ffffff;*/
    color: #333;
    font-family: var(--font1);
    font-size: 11.5pt;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media print {

    .resume_header .title {
        padding: 6px 8px;
        font-size: 10pt;
    }
}

.resume_header .name {
    display: flex;
    margin-top: 25px;
    margin-bottom: 15px;
    white-space: nowrap;
    font-size: 18pt;
    font-family: var(--font1);
    text-shadow: #666 0 0 3px;
    -webkit-print-color-adjust:exact;
    -webkit-filter:opacity(1);
}

@media print {
    .resume_header .name {
        font-size: 17pt;
    }
}

.resume_header .location {
    font-size: 10.5pt;
    font-family: var(--font1);
    text-shadow: #666 0 0 3px;
    -webkit-print-color-adjust:exact;
    -webkit-filter:opacity(1);
}

.resume_header .photo {
    align-self: center;
    margin-top: 35px;
    opacity: 0.9;
    border-radius: 20px;
    border: 6px dashed #9bd7ea;
    box-shadow: 0 0 8px 0 lightgrey, 0 0 15px 0 black;
}

.resume_header .contact_info_box {
    margin-bottom: 20px;
}

.resume_header .contact_info_header {
    margin-top: 40px;
    margin-bottom: 5px;
    font-family: var(--font1);
    font-weight: bolder;
    font-size: 12pt;
    text-shadow: #666 0 0 3px;
    -webkit-print-color-adjust:exact;
    -webkit-filter:opacity(1);
}

.resume_header .contact_info {
    margin-bottom: 5px;
    font-family: var(--font1);
    font-size: 10.5pt;
    text-shadow: #666 0 0 3px;
    -webkit-print-color-adjust:exact;
    -webkit-filter:opacity(1);
}

.resume_header .contact_info>a {
    color: white;
    text-decoration: none;
}

@media print {
    .contact_info_box {
        display: flex;
        flex-direction: column;
        width: 200px;
        padding-top: 0;
    }

    .contact_info {
        display: flex;
        font-size: 9.25pt !important;
        justify-content: center;
    }

    .contact_info_email {
        letter-spacing: 0.09rem;
    }

}

.sections {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
}

.sections > .section {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    padding-top: 22px;
}

.spacer {
    padding-top: calc(var(--topmarg) - 22px);
    flex-grow: 0;
}

@media print {
    .spacer {
        padding-top: 23px;
    }
}

.section .heading {
    padding: 10px 10px 10px 24px;
    background-color: var(--bgcol2);
    margin-top: 1px;
    /*box-shadow: 2px 0px 1px 0 white, 3px 2px 5px 0 #8c8c8c, 2px 0 1px 0 #bac7c7;*/
    border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    border-radius: 2px 0 0 2px;
    color: white;
    font-family: var(--font1);
    font-size: 11pt;
    font-weight: 600;
    letter-spacing: var(--letspace);
    text-transform: uppercase;
    text-shadow: #111e1e 0 0 1px ;
    -webkit-print-color-adjust:exact;
    -webkit-filter:opacity(1);
    z-index: 1;
}

@media print {

    .section .heading {
        font-size: 10pt;
        padding: 7px 10px 7px 24px;
    }
}

.section .heading>span {
    text-transform: lowercase;
    font-size: 10pt;
    letter-spacing: 0.015rem !important;
}

@media print {

    .hideOnPrint {
        display: none !important;
    }

    .section .heading>span.hideOnPrint {
        display: none;
    }

    .section .heading>span.showOnPrint {
        font-size: 9.5pt;
    }

}

.section .heading>a {
    color: #ca9af1;
    text-decoration: none;
}

.section .content_col {
    background-color: white;
    margin-top: 2px;
    margin-right: 55px;
    border-radius: 3px;
    border: 1px solid #a9a9a9;
    max-width: 850px;
}

@media print {

    .section .content_col {
        max-width: none !important;
        margin-right: 32px !important;
    }
}

.section .content_box {
    display: flex;
    flex-direction: row;
}

.section .footer {
    background-color: var(--bgcol3);
    height: 3px;
    margin-top: 6px;
    margin-bottom: 5px;
}

.section .blue_div {
    flex-grow: 0;
    margin: 13px 10px 10px 25px;
    width: 3px;
    max-width: 3px;
    min-width: 3px;
    background-color: #8ec6d9;
    /*box-shadow: 0 0 1px 1px white, 0 0 2px 1px #b4d3de;*/
}

.section .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 15px 30px 12px 17px;
}

.section .content>p {
    margin: 5px 0;
}

.section .content > div > p > span {
    font-weight: 1000;
}

.end_spacer {
    display: inline-flex;
    width: 100%;

    position: fixed;
    bottom: 0;
    left: 0;
    padding-top: 40px;
}

.display_on_print {
    display: none;
}

@media print {

    .page_break {
        page-break-before: always;
        height: 0;
    } /* page-break-after works, as well */

    .display_on_print {
        display: flex;
    }
}
